.container {
  width: 100vw;
  height: 100vh;
  background-color: #039be5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorContainer {
  width: 40rem;
  height: 25rem;
  background: #D8D8D8;
  box-shadow: 0px 2.99559px 4.19383px 0.599119px rgba(0, 0, 0, 0.5);
  border-radius: 12.5815px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorMsg {
	font-family: GothamNarrow-Medium, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21.5683px;
  line-height: 26px;
  text-align: center;

  color: #0A313F;
}
