
  
  .topBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .modalTitle {
      font-family: GothamNarrow-Medium, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      display: inline;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    margin: 0;
  }
  
  .columnHeader {
    border-radius: 0;
  }
  
  .columnHeaderTitle {
      font-family: GothamNarrow-Medium, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0A313F;
  }
  
  .columnSelect {
    border-radius: 10px 0px 0px 0px;
  }
  
  .columnCenter {
    text-align: center;
  }
  
  .error {
    display: flex;
    padding-bottom: 0.5rem;
    color: #CF4F43;
  }